import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpService } from "../_services/http.service";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { DomSanitizer } from "@angular/platform-browser";
import * as configList from "../../assets/config/config";
import { CONFIGUREPORTALREQUEST } from "../_models/ConfigurePortal/configureportalrequest";
import { LOGINUSERTRACK } from "../_models/UserActivityTrack/loginusertrack";
import { UserService } from "../_services/user.service";
import { KeycloakService } from "../keycloak/keycloak.service";
import { SessionService } from "../session.service";
 
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("username") username: any;
  @ViewChild("loginModal") loginModal: any;
  @ViewChild("loginMsg") loginMsg: any;
  loginForm: FormGroup;
  submitted: boolean;
  uname: boolean;
  pwd: boolean;
  url: string;
  showLoginModal = false;
  invalidAccount = false;
  passwordExpiry = false;
  pwdResetRem = false;
  public errorMsg: string;
  public tooltipconstdata = configList.tooltipConst;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public loginUserTrack: LOGINUSERTRACK;
  public ssoUserName: string;
  public ssoEmailId: string;
  public ssoMode: boolean;
  public ssoUserFirstName: string;
  public ssoUserLastName: string;
 
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpService: HttpService,
    private logger: NGXLogger,
    private sanitizer: DomSanitizer,
    private userDet: UserService,
    private kc: KeycloakService,
    private sessionService: SessionService
  ) {
    // this.getzipData();
    this.getssoMode();
    // if (this.ssoMode) {
    //console.log(this.kc.authenticated());
    if (this.kc.authenticated()) {
      this.kc.loadUserProfile();
    }
    // Parent Window fetch message from child window
    window.addEventListener(
      "message",
      (e) => {
        console.log(
          "Data recieved from KC " +
            JSON.stringify(e.data) +
            " username " +
            e.data.username
        );
        //if (e.data.hasOwnProperty('username')) {
        const username = sessionStorage.getItem("loggedInUserId");
        if (e?.data?.kcsessionid) {
          sessionStorage.setItem("kcsessionid", e?.data?.kcsessionid);
        }
        if (e?.data?.username) {
          console.log("inside username " + e.data.username);
          this.ssoUserName = e.data.username;
          sessionStorage.setItem("isLoggedIn", "true");
          // sessionStorage.setItem('loggedInUserName', e.data.username);
          console.log("inside ssoUserName " + this.ssoUserName);
          sessionStorage.setItem("loggedInUserId", e.data.username);
          sessionStorage.setItem("loggedInUserName", e.data.username);
          sessionStorage.setItem("loggedInFirstName", e.data.firstName);
          if (e.data.email) this.ssoEmailId = e.data.email;
          if (e.data.firstName) this.ssoUserFirstName = e.data.firstName;
          if (e.data.lastName) this.ssoUserLastName = e.data.lastName;
          sessionStorage.setItem("loginemail", e.data.email);
          sessionStorage.setItem("loginUserStatus", "A");
          sessionStorage.setItem("logOut", "N");
          this.login();
          // router.navigate(['/signInAnnouncement']);
        }
        if (username || e?.data?.username) {
          this.sessionService.startSessionValidation();
        }
      },
      false
    );
    // }
    const username = sessionStorage.getItem("loggedInUserId");
      if (username ) {
        this.sessionService.startSessionValidation();
      }    
  }
 
  ngOnInit() {
    this.submitted = false;
    this.uname = false;
    this.pwd = false;
    this.loginForm = this.formBuilder.group({
      userid: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }
 
  /*To Get sso mode is enabled or not if enabled, assign ssoMode variable to true */
  getssoMode() {
    this.ssoMode = sessionStorage.getItem("ssoMode") === "Y" ? true : false;
  }
 
  /** This method is used to fetch the US zip codes */
  /** getzipData() {
    this.httpService.get_zipDetails().subscribe(
      (data: any) => {
        sessionStorage.setItem('addressDetails', JSON.stringify(data));
        localStorage.setItem('addressDetails', JSON.stringify(data));
      },
      error => {
        this.logger.debug('Error while getting Zipcode data');
      }
    );
  } */
 
  get f() {
    return this.loginForm.controls;
  }
 
  onKey(event: any) {
    this.uname = false;
    this.pwd = false;
    this.submitted = false;
    if (event.target.id === "username") {
      this.uname = true;
    } else if (event.target.id === "password") {
      this.pwd = true;
    }
    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
  }
 
  passwordClear(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      this.f.password.setValue("");
    }
  }
 
  login() {
    this.getssoMode();
    this.invalidAccount = false;
    this.passwordExpiry = false;
    this.pwdResetRem = false;
    // stop here if form is invalid
    if (!this.ssoMode && this.loginForm.invalid) {
      this.submitted = true;
      this.uname = true;
      this.pwd = true;
      return;
    } else {
      this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
      this.configurePortalRequest.screenId =
        configList.pageConst.pagePublicFacing;
      this.configurePortalRequest.updatedBy = this.ssoMode
        ? this.ssoUserName
        : this.f.userid.value;
      this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
      this.configurePortalRequest.activityCode =
        configList.activityCode.loginAuditActCode;
      const ruleEngineData = Object();
      ruleEngineData.userLoginId = this.ssoMode
        ? this.ssoUserName
        : this.f.userid.value;
      ruleEngineData.password = this.ssoMode ? null : this.f.password.value;
      ruleEngineData.emailAddress = this.ssoMode ? this.ssoEmailId : null;
      this.configurePortalRequest.ruleEngineData = ruleEngineData;
 
      console.log(this.configurePortalRequest);
      localStorage.setItem("LoginTime", new Date().toLocaleString());
      this.httpService.userLoginTrack(this.configurePortalRequest).subscribe(
        (data) => {
          sessionStorage.removeItem("screenInfoData");
          localStorage.removeItem("screenInfoData");
          sessionStorage.setItem("isLoggedIn", "true");
          sessionStorage.setItem(
            "loggedInUserName",
            data.ruleEngineResp.userLoginId
          );
          sessionStorage.setItem(
            "loginRoleId",
            data.ruleEngineResp.parentRoleId
          );
          sessionStorage.setItem("userRoleId", data.ruleEngineResp.roleId);
          sessionStorage.setItem("loginScreenId", data.ruleEngineResp.screenId);
          sessionStorage.setItem("LoginToken", data.loginToken);
          if (
            sessionStorage.getItem("loginScreenId") !==
            data.ruleEngineResp.screenId
          ) {
            sessionStorage.setItem(
              "loginScreenId",
              data.ruleEngineResp.screenId
            );
          }
          sessionStorage.setItem(
            "loginUserStatus",
            data.ruleEngineResp.userStatus
          );
          sessionStorage.setItem("isTempPwd", data.ruleEngineResp.isTempPwd);
          sessionStorage.setItem("logOut", "N");
          sessionStorage.setItem("authToken", "ABCDEFGH");
 
          // Storing session data in localstorage for Session Management's Multiple tab support
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem(
            "loggedInUserName",
            data.ruleEngineResp.userLoginId
          );
          localStorage.setItem("loginRoleId", data.ruleEngineResp.parentRoleId);
          localStorage.setItem("userRoleId", data.ruleEngineResp.roleId);
          localStorage.setItem("loginScreenId", data.ruleEngineResp.screenId);
          localStorage.setItem(
            "loginUserStatus",
            data.ruleEngineResp.userStatus
          );
          sessionStorage.setItem("isTempPwd", data.ruleEngineResp.isTempPwd);
          localStorage.setItem("logOut", "N");
          localStorage.setItem("authToken", "ABCDEFGH");
          localStorage.setItem(
            "isAgreeClicked",
            sessionStorage.getItem("isAgreeClicked")
          );
 
          if (data.ruleEngineResp.responseCode === "RP000") {
            // Success Case
            /* if (sessionStorage.getItem('loginUserStatus') === 'N') {
              // New user Reset Password Case
              if (data.ruleEngineResp.isTempPwd === 'Y') {
                this.router.navigate(['/resetPassword']);
              } else {
                this.router.navigate(['/resetSecurityQuestion']);
              }
            } else if (data.ruleEngineResp.isTempPwd === 'Y') {
              // Temp Password case
              this.router.navigate(['/validateSecurityQuestion']);
            } else {
              this.router.navigate(['/signInAnnouncement']);
            } */
            if (sessionStorage.getItem("loginUserStatus") === "N") {
              // New user Reset Password Case
              if (data.ruleEngineResp.isTempPwd !== "Y") {
                this.router.navigate(["/resetSecurityQuestion"]);
              }
            } else if (
              !this.ssoMode &&
              sessionStorage.getItem("loginRoleId") === "3" &&
              sessionStorage.getItem("loginScreenId") === "4"
            ) {
              sessionStorage.removeItem("screenInfoData");
              sessionStorage.setItem("loginRoleId", "-1");
              sessionStorage.setItem("privilegeData", null);
              sessionStorage.setItem("userRoleId", "-1");
              sessionStorage.setItem("loginScreenId", "1");
              sessionStorage.setItem("logOut", "Y");
              sessionStorage.setItem("isLoggedIn", "false");
              sessionStorage.setItem("loggedInUserName", null);
              sessionStorage.removeItem("authToken");
              let tempAgreeClicked = localStorage.getItem("isAgreeClicked");
              localStorage.clear();
              if (tempAgreeClicked != null) {
                localStorage.setItem("isAgreeClicked", tempAgreeClicked);
              }
              this.router.navigate(["/unauthorized"]);
            } else {
              this.router.navigate(["/signInAnnouncement"]);
            }
            // this.router.navigate(['/signInAnnouncement']);
          } else if (
            data.ruleEngineResp.responseCode === "RP101" ||
            data.ruleEngineResp.responseCode === "RP102" ||
            data.ruleEngineResp.responseCode === "RP108" ||
            data.ruleEngineResp.responseCode === "RP111" ||
            data.ruleEngineResp.responseCode === "RP113" ||
            data.ruleEngineResp.responseCode === "RP114" ||
            data.ruleEngineResp.responseCode === "RP115"
          ) {
            // Account Invalid Cases
            this.invalidAccount = true;
            this.passwordExpiry = false;
            this.pwdResetRem = false;
            this.openLoginModal(data.ruleEngineResp.responseDesc);
          } else if (data.ruleEngineResp.responseCode === "RP109") {
            // Password Expiry Case
            this.invalidAccount = false;
            this.passwordExpiry = true;
            this.pwdResetRem = false;
            this.openLoginModal(data.ruleEngineResp.responseDesc);
          } else if (data.ruleEngineResp.responseCode === "RP110") {
            // Password Reset Reminder Case
            this.invalidAccount = false;
            this.passwordExpiry = false;
            this.pwdResetRem = true;
            this.openLoginModal(data.ruleEngineResp.responseDesc);
          } else {
            this.openLoginModal(data.ruleEngineResp.responseDesc);
            this.errorMsg = configList.errorConst.SystemError;
          }
        },
        (error) => {
          this.logger.debug("validating login error");
        }
      );
    }
  }
 
  openLoginModal(responseDesc) {
    this.errorMsg = responseDesc;
    this.showLoginModal = true;
    this.loginModal.nativeElement.style.display = "block";
    this.loginMsg.nativeElement.focus();
  }
 
  /* Method to close the modal */
  confirmLoginModal() {
    if (this.passwordExpiry) {
      this.router.navigate(["/changePassword"]);
    } else if (this.pwdResetRem) {
      this.router.navigate(["/signInAnnouncement"]);
    } else {
      this.showLoginModal = false;
      this.loginModal.nativeElement.style.display = "none";
      this.loginForm.get("password").setValue("");
      this.username.nativeElement.focus();
    }
  }
 
  /* Navigate to Forgot Password */
  rerouteFP() {
    this.router.navigate(["/forgotPassword"]);
  }
}